<template>
  <b-modal
    :no-close-on-backdrop="loading > 0"
    :hide-header-close="loading > 0"
    ref="moveSlots"
    @hide="modalHidden"
    :title="$t('calendarModule.moveSlots.title')"
    :hide-footer="true"
  >
    <div v-if="loading">
      <div class="d-flex justify-content-between mx-5 mt-3 mb-5">
        <div class="spinner-border text-success align-self-center loader-lg"/>
      </div>
    </div>
    <div v-else-if="loadingError" class="cm-loading-error">
      {{ $t("calendarModule.errors.loadingError") }}
    </div>
    <div v-else-if="finished">
      <b-icon-check /> {{ $t("calendarModule.moveSlots.finished") }}
    </div>
    <div v-else>
      <p>
        {{
          $t("calendarModule.moveSlots.amountInfo").replace(
            "{amount}",
            slots.length
          )
        }}
      </p>
      <b-form-group
        :description="$t('calendarModule.slotForm.descriptions.date')"
      >
        <template v-slot:label>
          {{ $t("calendarModule.slotForm.labels.date") }}
        </template>
        <div v-if="isClientRequest">
          <v-select
            v-model="slotOption"
            :options="slotOptions"
            class="rounded-0"
            :reduce="o => o.code"
          />
        </div>
        <div v-else>
          <date-picker
            class="w-100"
            format="DD.MM.YYYY"
            input-class="form-control pac-target-input"
            v-model="editSlotDate"
            @change="editSlotDateChanged"
          ></date-picker>
        </div>
      </b-form-group>
      <!--<b-checkbox v-model="sendRescheduleNotification">
        {{ $t("calendarModule.slotForm.sendRescheduleNotification") }}
      </b-checkbox>-->
      <b-button
        v-if="editSlotDate && isClientRequest"
        size="xs"
        class="mt-2"
        variant="danger"
        @click="() => confirmedForClient()"
      >
        {{ $t("calendarModule.moveSlots.confirm") }}
      </b-button>
      <b-button
        v-if="editSlotDate && !isClientRequest"
        size="xs"
        class="mt-2"
        variant="danger"
        @click="() => confirmed()"
      >
        {{ $t("calendarModule.moveSlots.confirm") }}
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import * as cmApi from "../../../store/modules/calendarModule/calendarModule.api";
import * as conversationApi from "../../../store/modules/conversations/conversations.api";
import * as moment from "moment";
import DatePicker from "vue2-datepicker";
import { mapGetters } from "vuex";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { convertToLocal, convertToCalendarTime } from "../../../utils/timezoneHelper";

export default {
  name: "MoveSlots",
  props: ["slots", "isClientRequest", "conversationForClientAction"],
  components: {
    vSelect,
    DatePicker
  },
  data() {
    return {
      slotOption: null,
      hostFreeSlots: [],
      conversation: null,
      currentUser: null,
      cmApi,
      loading: 0,
      loadingError: false,
      finished: false,
      sendRescheduleNotification: false,
      editSlotDate: new Date()
    };
  },
  computed: {
    ...mapGetters("users",["getCurrentUser"]),
    slotOptions() {
      const options = this.hostFreeSlots.map(el => {
        return {
          label: el.date + ": " + el.time + " - " + el.endTime, // el.basicProfile.firstName + " " + el.basicProfile.lastName,
          code: el._id //el._id
        };
      });
      return options;
    }
  },
  methods: {
    slotsChanged() {
      this.$emit("slotsChanged");
    },
    modalHidden() {
      this.$emit("hidden");
    },
    setSearchIdentifier(slotId, identifier) {
      this.searchIdentifiers[slotId] = identifier;
    },
    confirmedForClient() {
      this.loading = true;
      this.loadingError = false;
      conversationApi
        .updateConversation(this.conversation._id, {
          appointment: this.slotOption,
          host: this.conversation.host._id,
          subject: this.conversation.subject
        })
        .then(res => {
          this.loading = false;
          this.loadingError = false;
          this.slotsChanged();
        })
        .catch(err => {
          this.loading = false;
          this.loadingError = true;
        });
    },
    confirmed() {
      const newDateStr = moment(this.editSlotDate).format("YYYY-MM-DD");
      for (const slot of this.slots) {
        this.loading++;
        slot.date = newDateStr;
        slot.endDate = newDateStr;
        const slotPayload = {
          time: slot.time,
          endTime: slot.endTime,
          date: slot.date,
          endDate: slot.endDate,
          metadata: {
            type: slot.metadata.type
          }
        };
        cmApi.slot
          .updateTimeSlot(this.currentUser._id, slot._id, slotPayload)
          .then(res => {
            this.loading--;
            this.loadingError = false;
            if (!this.loading) {
              this.slotsChanged();
            }
          })
          .catch(e => {
            this.loading--;
            this.loadingError = false;
          });
      }
    },
    editSlotDateChanged() {
      if (this.editSlotDate) {
        const baseMoment = moment(this.editSlotDate);
        baseMoment.add(13, "hours");
        this.editSlotDate = baseMoment.toDate();
      }
    },
    getAllConversationInformation() {
      this.loading = true;
      this.loadingError = false;
      cmApi.slot
        .getAllTimeSlots(this.conversation.host._id)
        .then(res => {
          this.loading = false;
          this.loadingError = false;
          const timeSlots = res.data.data;
          const convertedSlots = [];
          timeSlots.forEach(slot => {
            const convertedUtc = convertToLocal(
              slot.date,
              slot.endDate,
              slot.time,
              slot.endTime
            );
            const payload = {
              date: convertedUtc.date,
              endDate: convertedUtc.endDate,
              time: convertedUtc.time,
              endTime: convertedUtc.endTime,
              free: slot.free,
              _id: slot._id
            };
            convertedSlots.push(payload);
          });

          const filteredSlots = convertedSlots.filter(
            slot =>
              slot.free === true &&
              moment(slot.date, "YYYY-MM-DD").diff(
                moment().format("YYYY-MM-DD")
              ) > 0
          );
          this.hostFreeSlots = filteredSlots;
        })
        .catch(err => {
          this.loading = false;
          this.loadingError = true;
        });
    }
  },
  mounted() {
    if (this.getCurrentUser) {
      this.currentUser = this.getCurrentUser;
    }
    if (this.conversationForClientAction) {
      this.conversation = this.conversationForClientAction;
      this.getAllConversationInformation();
    }
    this.$refs["moveSlots"].show();
  }
};
</script>
